import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardModule } from "primeng/card";

@Component({
  selector: "db-dialog-card",
  templateUrl: "./dialog-card.component.html",
  styleUrls: ["./dialog-card.component.scss"],
  imports: [CommonModule, CardModule],
  standalone: true,
})
export class DialogCardComponent {
  @Input() noFooter = false;
  @Input() noHeader = false;
  @Input() noBody = false;
  @Input() dataTestId?: string;
  @Input() footerTopBorder?: boolean;
}
