import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from "@angular/core";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: "db-info-icon",
  standalone: true,
  imports: [TooltipModule, NgClass],
  templateUrl: "./info-icon.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoIconComponent {
  @Input() tooltipText?: string | TemplateRef<HTMLElement>;
  @Input() iconSizeClass: "icon-size-14" | "icon-size-16" = "icon-size-14";
  @Input() escape: boolean = true;
  @Input() autoHide: boolean = true;
}
