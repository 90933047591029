import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { ErrorDictionaryEntry, standardErrorDictionary } from "types";
import { ExecPipe } from "shared-pipes";
import { calendarDayToShortDateString, dateNoTimeInfo } from "shared-utils";

@Component({
  selector: "db-date-picker-input",
  standalone: true,
  imports: [CommonModule, CalendarModule, FormsModule, ExecPipe],
  templateUrl: "./date-picker-input.component.html",
  styleUrls: ["./date-picker-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatePickerInputComponent,
      multi: true,
    },
  ],
})
export class DatePickerInputComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  readonly calendarDayToShortDateString = calendarDayToShortDateString;
  errorDictionaryList: ErrorDictionaryEntry[] = standardErrorDictionary;
  @Input() name = "";

  @Input() set errorListDictionary(dictionary: ErrorDictionaryEntry[]) {
    if (dictionary && dictionary.length > 0) {
      this.errorDictionaryList = [
        ...standardErrorDictionary.filter(
          (error) => !dictionary.some((d) => d.name === error.name),
        ),
        ...dictionary,
      ];
    }
  }

  @Input() placeholder = "";
  @Input() required = false;
  @Input() optional = false;
  @Input() label = "";
  @Input() minDate: Date | undefined;
  @Input() maxDate: Date | undefined;
  @Input() disableWeekends = false;
  @Input() disabledWeekdays: number[] = [];
  @Input() withIconBackgroundWhite = false;
  @Input() dataTestId?: string;
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() hideInternalError = false;

  @Output() selectedValueChanged = new EventEmitter<string>();

  private onChange!: (value: string) => {};
  private onTouch!: () => {};
  private readonly injector = inject(Injector);
  changeDetectorRef = inject(ChangeDetectorRef);

  disabled = false;
  inputId = "";
  control: FormControl | undefined;
  value: Date | null = null;

  ngOnInit(): void {
    this.inputId = `input-id-${this.name}`;
  }

  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl, null, { optional: true })
      ?.control as FormControl;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  writeValue(value: string | Date | null): void {
    this.setValue(value);
    this.changeDetectorRef.detectChanges();
  }

  valueChangedHandler(value: string): void {
    this.setValue(value);
    if (this.onChange) {
      this.onChange(value);
    }

    this.registerOnTouched(value);

    this.selectedValueChanged.emit(value);
    this.changeDetectorRef.detectChanges();
  }

  disabledDays = (
    disabledWeekdays: number[],
    disableWeekends: boolean,
  ): number[] => {
    const disabledWeekdayNumbers = [];
    if (disabledWeekdays) {
      disabledWeekdayNumbers.push(...disabledWeekdays);
    }

    if (disableWeekends) {
      if (!disabledWeekdayNumbers.includes(0)) {
        disabledWeekdayNumbers.push(0);
      }
      if (!disabledWeekdayNumbers.includes(6)) {
        disabledWeekdayNumbers.push(6);
      }
    }

    return disabledWeekdayNumbers.sort((a, b) => a - b);
  };

  private setValue(value: string | Date | null): void {
    // calendar component should not work with any timezone since it can cause converting of the Date later when taking the value after selection
    this.value = dateNoTimeInfo(value);
  }
}
