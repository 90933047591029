<p-inputSwitch
  [inputId]="inputId || dataTestId || undefined"
  [(ngModel)]="value"
  [disabled]="disabled"
  (onChange)="valueChangedHandler($event)"
  [attr.data-testId]="dataTestId"
>
</p-inputSwitch>
<div class="labels-wrapper">
  <label
    class="flex items-center"
    [ngClass]="{
      'small-text': !description,
      'small-text-bold': !!description,
    }"
    [for]="inputId || dataTestId || undefined"
  >
    {{ label }} <ng-content></ng-content>
  </label>

  @if (value && labelTrue) {
    <label class="small-text" [for]="inputId || dataTestId || undefined">
      {{ labelTrue }}
    </label>
  }
  @if (!value && labelFalse) {
    <label class="small-text" [for]="inputId || dataTestId || undefined">
      {{ labelFalse }}
    </label>
  }
  @if (description) {
    <div class="small-text">
      {{ description }}
    </div>
  }
</div>
