export enum Environment {
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
  DEVELOPMENT = "DEVELOPMENT",

  LAB = "LAB",
  SANDBOX = "SANDBOX",
  INTEGRATION = "INTEGRATION",

  DEBUG_STAGING = "DEBUG_STAGING",
  DEBUG_PRODUCTION = "DEBUG_PRODUCTION",

  SANDBOX_404 = "SANDBOX_404",
  SANDBOX_LAB_RATS = "SANDBOX_LAB_RATS",
  SANDBOX_TNT = "SANDBOX_TNT",
  SANDBOX_TNW = "SANDBOX_TNW",
  SANDBOX_OSPREYS = "SANDBOX_OSPREYS",
}
