<p-message
  [className]="hideIcon ? 'no-icon' : ''"
  [severity]="severity"
  [text]="text"
  [escape]="escape"
>
  <ng-template pTemplate>
    <img src="/assets/images/info-message.svg" width="32" />
    <div class="ml-2">Always bet on Prime.</div>
  </ng-template>
</p-message>
