<ng-template
  #missingTemplate
  i18n="@@confirmation-module|confirmation|template-missing"
>
  Template missing!
</ng-template>

<ng-template #standardConfirmationHeaderTemplate let-content="content">
  <h3 class="text-black">{{ content || "" }}</h3>
</ng-template>

<ng-template
  #standardConfirmationBodyTemplate
  let-content="content"
  let-infoText="infoText"
>
  <div class="content small-text">{{ content || "" }}</div>
  @if (infoText) {
    <div class="info-text-box">
      <db-info-message [text]="infoText"></db-info-message>
    </div>
  }
</ng-template>

@for (
  confirmationConfig of confirmationConfigs$ | async;
  track confirmationConfig
) {
  <db-dialog-container
    [width]="confirmationConfig.width || '600px'"
    (closeDialog)="cancelClickHandler(confirmationConfig)"
  >
    <db-dialog-card>
      <ng-container
        uiCardHeader
        *ngTemplateOutlet="
          (confirmationConfig.isGeneric
            ? standardConfirmationHeaderTemplate
            : (
                activeDialogs
                | exec
                  : __getConfirmationInstanceByName
                  : confirmationConfig.name
              ).header) || missingTemplate;
          context: {
            $implicit: confirmationConfig.data,
            instance: this,
            content: confirmationConfig?.headerText || '',
          }
        "
      >
      </ng-container>
      <ng-container
        uiCardBody
        *ngTemplateOutlet="
          (confirmationConfig.isGeneric
            ? standardConfirmationBodyTemplate
            : (
                activeDialogs
                | exec
                  : __getConfirmationInstanceByName
                  : confirmationConfig.name
              ).body) || missingTemplate;
          context: {
            $implicit: confirmationConfig.data,
            instance: this,
            content: confirmationConfig?.bodyText || '',
            infoText: confirmationConfig?.infoText || '',
          }
        "
      >
      </ng-container>
      <ng-container uiCardFooter>
        @if (!confirmationConfig.hideActions) {
          <div class="actions flex">
            @if (!confirmationConfig.hideCancel) {
              <db-button
                class="flex-1"
                [width]="'full-width'"
                [buttonType]="'outlined'"
                [buttonType]="'outlined'"
                [disabled]="!!(disableCancel$$ | async)"
                (click)="cancelClickHandler(confirmationConfig)"
              >
                {{
                  confirmationConfig.cancelButtonLabel || cancelLabelDefault
                }}</db-button
              >
            }
            @if (!confirmationConfig.hideConfirm) {
              <db-button
                class="flex-1 ml-2xl"
                [width]="'full-width'"
                [disabled]="!!(disableConfirm$$ | async)"
                (click)="confirmClickHandler(confirmationConfig)"
              >
                {{
                  confirmationConfig.confirmButtonLabel ||
                    confirmationLabelDefault
                }}
              </db-button>
            }
          </div>
        }
      </ng-container>
    </db-dialog-card>
  </db-dialog-container>
}
