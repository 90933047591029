import { NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  inject,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
} from "@angular/forms";
import { EditorModule } from "primeng/editor";
import { RippleModule } from "primeng/ripple";
import { ErrorDictionaryEntry, standardErrorDictionary } from "types";

const primeImports = [EditorModule, RippleModule];

@Component({
  selector: "db-editor",
  templateUrl: "./editor.component.html",
  standalone: true,
  imports: [FormsModule, NgTemplateOutlet, ...primeImports],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EditorComponent,
      multi: true,
    },
  ],
})
export class EditorComponent implements ControlValueAccessor, AfterViewInit {
  control: FormControl | undefined;
  errorDictionaryList: ErrorDictionaryEntry[] = standardErrorDictionary;

  @Input() name = "";
  @Input() set errorListDictionary(dictionary: ErrorDictionaryEntry[]) {
    if (dictionary && dictionary.length > 0) {
      this.errorDictionaryList = [
        ...standardErrorDictionary.filter(
          (error) => !dictionary.some((d) => d.name === error.name),
        ),
        ...dictionary,
      ];
    }
  }
  @Input() htmlValue = "";
  @Input() placeholder = "";
  @Input() label = "";
  @Input() height = "100px";
  @Input() dataTestId?: string;

  private readonly injector = inject(Injector);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private onChange!: (value: string) => {};
  private onTouch!: () => {};

  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl, null, { optional: true })
      ?.control as FormControl;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.htmlValue = value;
    this.changeDetectorRef.detectChanges();
  }

  valueChangedHandler(event: { htmlValue?: string }): void {
    this.htmlValue = event.htmlValue || "";
    if (this.onChange) {
      this.onChange(this.htmlValue);
    }
    this.onTouch();
  }
}
