@if (!!label) {
  <label class="block small-text-bold mb-sm">
    {{ label }}
    @if (showAsterisk) {
      *
    }
    @if (optional) {
      <i i18n="@@shared|optional-label">(optional)</i>
    }
  </label>
}
<div class="db-autocomplete-users-multi-select">
  <p-autoComplete
    [delay]="0"
    [ngModel]="currentSelection"
    [dataKey]="'key'"
    [optionLabel]="'key'"
    [suggestions]="suggestions"
    (completeMethod)="onSearch($event)"
    [multiple]="true"
    [placeholder]="placeholder"
    [size]="'small'"
    [forceSelection]="true"
    [autoOptionFocus]="true"
    [showClear]="showClear"
    [emptyMessage]="emptyMessage"
    [minLength]="1"
    [scrollHeight]="scrollHeight"
    [lazy]="true"
    [style]="inputStyle"
    [styleClass]="styleClass"
    [panelStyle]="panelStyle"
    (onSelect)="onSelect($event)"
    (onUnselect)="onUnselect($event)"
    (onClear)="onClear()"
    (onBlur)="onBlur()"
    [attr.data-testid]="dataTestId"
    [inputStyleClass]="'db-autocomplete-users-input'"
    [styleClass]="
      'w-full' +
      (hideSelected ? ' db-autocomplete-users-show-selected-items-outside' : '')
    "
  >
    <ng-template let-suggestion let-i="index" pTemplate="item">
      <div class="flex items-center" [ngStyle]="{ 'max-width': '462px' }">
        @switch (suggestion.type) {
          @case ("user") {
            <db-user-info
              [style]="{ width: '100%' }"
              [userInfo]="suggestion.user"
              [showUserEmail]="true"
              [fullWidth]="true"
              containerPadding="10px 16px"
              containerDataTestId="autocomplete--search-result"
              avatarDataTestId="autocomplete--search-result-avatar"
              [showEmailIfNameNotAvailable]="true"
              [ignoreEmailMaxWidth]="true"
              [openProfileOnClick]="false"
              [attr.data-testid]="
                dataTestId + '--search-result-user-' + suggestion.user.id
              "
            />
          }
          @case ("group") {
            <div
              class="custom-list-item"
              [attr.data-testid]="
                dataTestId + '--search-result-group-' + suggestion.group.id
              "
            >
              <db-user-group-avatar [avatarSize]="'mini-no-border'" />
              <span class="title ml-xl">
                {{ suggestion.group.name }} ({{ suggestion.group.userCount }})
              </span>
            </div>
          }
          @case ("externalEmail") {
            <div
              class="custom-list-item"
              [attr.data-testid]="dataTestId + '--search-result-email-' + i"
            >
              <div class="icon email-icon">
                <i class="svg-email"></i>
              </div>
              <span class="title">
                {{ suggestion.email }}
              </span>
            </div>
          }
        }
      </div>
    </ng-template>
    <ng-template let-selectedItem let-i="index" pTemplate="selectedItem">
      @if (!hideSelected) {
        @switch (selectedItem.type) {
          @case ("user") {
            <db-avatar-chip
              class="d-inline-flex"
              [label]="
                selectedItem.user.firstName
                  ? selectedItem.user.firstName +
                    ' ' +
                    selectedItem.user.lastName
                  : selectedItem.user.email
              "
              [avatarColor]="selectedItem.user.avatarColor"
              [avatarImgSrc]="selectedItem.user.profileImage"
              [size]="'normal'"
              [attr.data-testid]="dataTestId + '--selected-item-user-' + i"
            />
          }
          @case ("group") {
            <div
              class="custom-pill"
              [attr.data-testid]="dataTestId + '--selected-item-group-' + i"
            >
              <span class="title ml-xl">
                {{ selectedItem.group.name }} ({{
                  selectedItem.group.userCount
                }})
              </span>
              @if (
                enableUserSelection &&
                enableGroupExpand &&
                selectedItem.group.userCount > 0
              ) {
                <span>
                  <i
                    class="pi pi-window-maximize expand-icon"
                    [pTooltip]="
                      groupTooltipContent[selectedItem.group.id] || ''
                    "
                    tooltipPosition="top"
                    [class.disabled]="
                      selectedItem.group.userCount > groupTooltipExpandUserLimit
                    "
                    (mouseenter)="onGroupTooltipHover(selectedItem.group)"
                    (click)="
                      selectedItem.group.userCount > groupTooltipExpandUserLimit
                        ? null
                        : onGroupTooltipClick(selectedItem.group)
                    "
                  ></i>
                </span>
              }
            </div>
          }
          @case ("externalEmail") {
            <div
              class="custom-pill"
              [attr.data-testid]="dataTestId + '--selected-item-external-' + i"
            >
              <div class="icon email-icon">
                <i class="svg-email"></i>
              </div>
              <span class="title">
                {{ selectedItem.email }}
              </span>
            </div>
          }
        }
      }
    </ng-template>
    <ng-template pTemplate="removetokenicon">
      @if (!hideSelected) {
        <i class="svg-delete-sign remove-pill-icon"></i>
      }
    </ng-template>
  </p-autoComplete>
</div>
