import moment, { Moment } from "moment";

/** Last day in lead time on which a booking could be made */
export function getBookingLeadTime(
  officeTimeZone: string,
  resourcesBookableUntil?: string,
): Moment {
  if (!resourcesBookableUntil) {
    return moment().tz(officeTimeZone).endOf("day");
  }

  return moment(resourcesBookableUntil).tz(officeTimeZone);
}
