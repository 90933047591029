import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { IUserInfo } from "types";
import { hasAccessToPageFactory } from "shared";
import { MenuItem } from "primeng/api";
import { ExecPipe } from "shared-pipes";
import { MenuComponent, UserInfoComponent } from "db-ui";
import { GlobalModule } from "global-module";
import { NgIf } from "@angular/common";
import { MenuItemWithChildrenComponent } from "../menu-item-with-children/menu-item-with-children.component";
import { MenuItemComponent } from "../menu-item/menu-item.component";

@Component({
  selector: "db-admin-navigation",
  templateUrl: "./admin-navigation.component.html",
  styleUrls: ["./admin-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MenuItemComponent,
    MenuItemWithChildrenComponent,
    NgIf,
    GlobalModule,
    UserInfoComponent,
    MenuComponent,
    ExecPipe,
  ],
})
export class AdminNavigationComponent implements OnChanges {
  @Input() isExpanded = false;
  @Input() officeId!: string | null;
  @Input() companyId!: string;

  @Input() hasPlanningAccess = false;
  @Input() hasBookingAccess = false;
  @Input() hasIntegrations = false;
  @Input() publicApiKeysPageAllowed = false;

  @Input() isUserAdmin = false;
  @Input() isUserManager = false;
  @Input() isUserGroupManager = false;
  @Input() isUserGuest = false;
  @Input() isUserOfficeAdmin = false;
  @Input() isUserRegularUser = false;
  @Input() isUserDeskbirdAdmin = false;
  @Input() userRoleCompanyPageRestrictions: string[] | null = null;
  @Input() officeEquipmentEnabled!: boolean;
  @Input() hybridWorkPoliciesEnabled!: boolean;
  @Input() customAnalyticsEnabled = false;
  @Input() cateringAndServicesEnabled!: boolean;
  @Input() kioskModeEnabled!: boolean;

  @Input() userProfileImageUrl: string | null | undefined;
  @Input() userInfo!: IUserInfo;

  @Input() adminPartPrefix!: string;
  @Input() clientPartPrefix!: string;
  @Input() hideIcons = false;

  @Output() navigateToProfile = new EventEmitter<void>();
  @Output() signOut = new EventEmitter<void>();

  publicApiKeysTooltipLabel(): string {
    return this.publicApiKeysPageAllowed
      ? ""
      : $localize`:@@navigation-module|admin-navigation|contact-your-csv:Contact your CSM for more information`;
  }

  menuItemLabels: { [key: string]: string } = {
    "office-settings": $localize`:@@navigation-module|admin-navigation|office-settings:Office settings`,
    "meeting-room-settings": $localize`:@@navigation-module|admin-navigation|meeting-room-settings:Meeting room settings`,
    "user-settings": $localize`:@@navigation-module|admin-navigation|user-settings:User settings`,
    "web-app": $localize`:@@navigation-module|admin-navigation|webapp:Web app`,
    analytics: $localize`:@@navigation-module|admin-navigation|analytics:Analytics`,
    "schedule-analytics": $localize`:@@navigation-module|admin-navigation|schedule-analytics:Workforce analytics`,
    "office-analytics": $localize`:@@navigation-module|admin-navigation|office-analytics:Office analytics`,
    bookings: $localize`:@@navigation-module|admin-navigation|bookings:Bookings`,
    "office-events": $localize`:@@navigation-module|admin-navigation|office-events:Events`,
    "office-events-roles": $localize`:@@navigation-module|admin-navigation|office-events-settings:Settings`,
    "hybrid-work-policies": $localize`:@@navigation-module|admin-navigation|hybrid-work-policies:Hybrid work policies`,
    "general-configuration": $localize`:@@navigation-module|admin-navigation|general-configuration:General configuration`,
    "floors-spaces": $localize`:@@navigation-module|admin-navigation|floors-spaces:Floors & spaces`,
    "office-equipment": $localize`:@@navigation-module|admin-navigation|office-equipment:Equipment`,
    "meeting-room-general-setup": $localize`:@@navigation-module|admin-navigation|meeting-room-general-setup:General setup`,
    "meeting-room-catering-and-services": $localize`:@@navigation-module|admin-navigation|meeting-room-catering-and-services:Catering & services`,
    "meeting-room-kiosk": $localize`:@@navigation-module|admin-navigation|meeting-room-kiosk:Kiosk`,
    user: $localize`:@@navigation-module|admin-navigation|user:User`,
    "user-groups": $localize`:@@navigation-module|admin-navigation|user-groups:User groups`,
    administration: $localize`:@@navigation-module|admin-navigation|administration:deskbird admin`,
    "booking-settings": $localize`:@@navigation-module|admin-navigation|booking-settings:Booking settings`,
    "company-settings": $localize`:@@navigation-module|admin-navigation|company-settings:Company settings`,
    integrations: $localize`:@@navigation-module|admin-navigation|integrations:Integrations`,
    subscription: $localize`:@@navigation-module|admin-navigation|subscriptions:Subscription`,
    "api-keys": $localize`:@@navigation-module|admin-navigation|api-keys:API keys`,
    workforce: $localize`:@@navigation-module|admin-navigation|workforce:Workforce`,
  };

  userMenuItems: MenuItem[] = [
    {
      icon: "user-icon",
      label: $localize`:@@navigation-module|client-navigation|profile:Profile settings`,
      command: () => this.navigateToProfile.emit(),
    },
    {
      icon: "sign-out-icon",
      label: $localize`:@@navigation-module|client-navigation|sign out:Sign out`,
      command: () => this.signOut.emit(),
    },
  ];

  hasAccessToPage: (args: string[] | null) => boolean = () => true;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      typeof changes["userProfileImageUrl"]?.currentValue === "string" &&
      this.userInfo
    ) {
      this.userInfo = {
        ...this.userInfo,
        profileImage: this.userProfileImageUrl as string,
      };
    }

    if (changes["userRoleCompanyPageRestrictions"]) {
      this.hasAccessToPage = hasAccessToPageFactory(
        this.userRoleCompanyPageRestrictions,
      );
    }
  }
}
