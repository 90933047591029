import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getAbbreviation } from "shared";
import { AUTH_STORE_NAME, NOT_SET } from "../constants";
import { IAuthState } from "./reducer";

const getAuthState = createFeatureSelector<IAuthState>(AUTH_STORE_NAME);

export const authSelectors = {
  user: createSelector(getAuthState, (state) => state.user),
  corporateInfo: createSelector(getAuthState, (state) => state.corporateInfo),
  isDeskbirdAdmin: createSelector(
    getAuthState,
    (state) => state.isDeskbirdAdmin,
  ),
  emailCheckResult: createSelector(
    getAuthState,
    (state) => state.emailCheckResult,
  ),
  publicDomains: createSelector(getAuthState, (state) => state.publicDomains),
  freeTrialStartDate: createSelector(
    getAuthState,
    (state) => state.freeTrialStartDate,
  ),
  freeTrialEndDate: createSelector(
    getAuthState,
    (state) => state.freeTrialEndDate,
  ),
  initials: createSelector(getAuthState, (state) =>
    !!state.user && state.user !== NOT_SET
      ? getAbbreviation(`${state.user.firstName} ${state.user.lastName}`)
      : "",
  ),
  profileImage: createSelector(getAuthState, (state) => {
    if (state.user === NOT_SET || !state.user) return "";
    const { profileImage } = state.user;
    if (!profileImage) return profileImage;
    const timestamp = new Date().getTime();
    return `${profileImage}?timestamp=${timestamp}`;
  }),
  enabledMixpanel: createSelector(getAuthState, (state) =>
    state?.user !== NOT_SET && !!state?.user
      ? state?.user?.userSettings?.app?.enableTracking?.mixpanel &&
        !state?.corporateInfo?.disableTrackingCompanyWide
      : false,
  ),
  enabledGoogleAnalytics: createSelector(getAuthState, (state) =>
    state?.user !== NOT_SET && !!state?.user
      ? state?.user?.userSettings?.app?.enableTracking?.googleAnalytics &&
        !state?.corporateInfo?.disableTrackingCompanyWide
      : false,
  ),
  avatarColor: createSelector(getAuthState, (state) =>
    state.user !== NOT_SET && !!state.user ? state.user.avatarColor : null,
  ),
  isSavingUser: createSelector(
    getAuthState,
    (state: IAuthState) => state.isSavingUser,
  ),
  userFeatureAccess: createSelector(
    getAuthState,
    (state: IAuthState) => state.userFeatureAccess,
  ),
};
