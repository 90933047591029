import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResult } from "types/interfaces/paginated-response";
import { ILoadUsersFilteredRequest_v3 } from "types/interfaces/user-filtered-request";
import { IUserInfo } from "types/interfaces/user-info";
import { IUserFilterService as IUserFilterService } from "./user-filter.interface";
import { UserGroupFilterService } from "./user-group-filter.service";

@Injectable({
  providedIn: "root",
})
export class UserFilterService
  extends UserGroupFilterService
  implements IUserFilterService
{
  loadFollowedUsers(
    companyId: string,
    offset: number = 0,
    limit: number = 100,
  ): Observable<PaginatedResult<IUserInfo>> {
    const query = `companyId=${companyId}&followStatus=following&offset=${offset}&limit=${limit}`;
    return this.http.get<PaginatedResult<IUserInfo>>(`/api/v3/users?${query}`);
  }

  loadUsersForCompanyFiltered({
    companyId,
    searchQuery,
    primaryOfficeIds,
    roles,
    userGroups,
    onlyUsersWithNoGroups,
    officeRolesIds,
    followStatus,
    userIds,
    userUuids,
    excludeUserIds,
    status,
    sortField,
    sortOrder,
    offset,
    limit,
    include,
  }: ILoadUsersFilteredRequest_v3): Observable<PaginatedResult<IUserInfo>> {
    let query = `offset=${offset}&limit=${limit}`;

    if (companyId) {
      query = query.concat(`&companyId=${companyId}`);
    }

    if (primaryOfficeIds?.length) {
      query = query.concat(`&primaryOfficeIds=${primaryOfficeIds.join(",")}`);
    }

    if (followStatus) {
      query = query.concat(`&followStatus=${followStatus}`);
    }

    if (sortField) {
      query = query.concat(`&sortField=${sortField}&sortOrder=${sortOrder}`);
    }

    if (userGroups?.length) {
      query = query.concat(`&userGroups=${userGroups.join(",")}`);
    }

    if (searchQuery) {
      query = query.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`);
    }

    if (roles?.length) {
      query = query.concat(`&roles=${roles.join(",")}`);
    }

    if (onlyUsersWithNoGroups) {
      query = query.concat(`&onlyUsersWithNoGroups=${onlyUsersWithNoGroups}`);
    }

    if (officeRolesIds?.length) {
      query = query.concat(`&officeRolesIds=${officeRolesIds.join(",")}`);
    }

    if (userIds?.length) {
      query = query.concat(`&userIds=${userIds.join(",")}`);
    }

    if (userUuids?.length) {
      query = query.concat(`&userUuids=${userUuids.join(",")}`);
    }

    if (excludeUserIds?.length) {
      query = query.concat(`&excludeUserIds=${excludeUserIds.join(",")}`);
    }

    if (status?.length) {
      query = query.concat(`&status=${status.join(",")}`);
    }

    if (include?.length) {
      query = query.concat(`&include=${include.join(",")}`);
    }

    return this.http.get<PaginatedResult<IUserInfo>>(`/api/v3/users?${query}`);
  }
}
