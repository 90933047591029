import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  SecurityContext,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ButtonComponent, ButtonLinkComponent } from "db-ui";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { GtmEventDirective } from "shared-directives";

@Component({
  selector: "db-paywall-content",
  templateUrl: "feature-access-paywall.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, NgClass, GtmEventDirective, ButtonLinkComponent],
  styleUrl: "feature-access-paywall.component.scss",
})
export class PopupContentComponent {
  private readonly sanitizer = inject(DomSanitizer);

  @Input() data?: {
    header?: string;
    description?: string;
    image?: string;
    buttonCancel?: { label: string; action: () => void };
    buttonPrimary?: { label: string; action: () => void };
    width?: string;
    entryPoint?: string;
    upsellPage?: string;
    link?: { label: string; action: () => void };
  };

  protected readonly dynamicDialogConfig = inject<
    DynamicDialogConfig<{
      description?: string;
      image?: string;
      buttonCancel?: { label: string; action: () => void };
      buttonPrimary?: { label: string; action: () => void };
      width?: string;
      entryPoint?: string;
      upsellPage?: string;
    }>
  >(DynamicDialogConfig, { optional: true });

  private readonly ref = inject(DynamicDialogRef, { optional: true });

  protected forceClose() {
    if (this.ref) this.ref.close();
  }

  get sanitizedDescription(): string | null {
    const description = this.dynamicDialogConfig?.data?.description;
    if (!description) return null;
    return this.sanitizer.sanitize(SecurityContext.HTML, description) ?? null;
  }
}
