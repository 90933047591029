<div class="flex justify-stretch items-center gap-2xl">
  @for (item of items; track item.id) {
    <div
      class="cardSelector-item flex flex-col self-stretch gap-2xl cursor-pointer p-2xl rounded-lg"
      [pTooltip]="item.tooltip"
      tooltipPosition="top"
      [tooltipOptions]="{ positionTop: 15 }"
      [ngClass]="{
        disabled: isDisabled || item.disabled,
        selected: value?.id === item.id,
      }"
      (click)="onSelectedItem(item, $event)"
    >
      <div
        class="cardSelector-item-image bg-center rounded-lg"
        [ngStyle]="{
          'background-image': 'url(' + item.image + ')',
        }"
      ></div>
      <div class="flex flex-col gap-sm">
        <div class="cardSelector-item-title flex items-center small-text-bold">
          <div>{{ item.title }}</div>
          @if (item.addUpgradeBadge) {
            <div
              class="cardSelector-item-upgrade flex items-center px-m py-sm ml-xl"
            >
              <span class="pi pi-arrow-up mr-sm" style="font-size: 9px"></span>
              <span>UPGRADE</span>
            </div>
          }
        </div>
        <div class="cardSelector-item-description very-small-text">
          {{ item.description }}
        </div>
      </div>
    </div>
  }
</div>
