import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogContainerService } from "./dialog-container.service";
import { ButtonIconComponent } from "../button-icon";

@Component({
  selector: "db-dialog-container",
  templateUrl: "./dialog-container.component.html",
  styleUrls: ["./dialog-container.component.scss"],
  standalone: true,
  imports: [CommonModule, ButtonIconComponent],
})
export class DialogContainerComponent implements OnInit, OnDestroy {
  private readonly elementRef = inject(ElementRef);
  private readonly dialogContainerService = inject(DialogContainerService);

  @Output() closeDialog = new EventEmitter<void>();

  @Input() width: string | undefined = "600px";
  @Input() height: string | undefined;
  @Input() showCloseButton = true;
  @Input() dataTestIdCloseButton: string | undefined;
  @Input() noContainerStyles = false;
  @Input() closeOnOutsideClick = true;

  @Input() @HostBinding("style.background-color") backgroundColor =
    "rgba(1, 1, 1, 0.77)";
  @HostListener("click", ["$event"]) componentClickHandler(
    event: MouseEvent,
  ): void {
    if (
      event.target === this.elementRef.nativeElement &&
      this.closeOnOutsideClick
    ) {
      this.closeDialog.emit();
    }
  }

  ngOnInit(): void {
    this.dialogContainerService.addDialog(this);
  }

  ngOnDestroy(): void {
    this.dialogContainerService.removeDialog(this);
  }
}
