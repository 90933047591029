<div class="ui-modal" [attr.data-testId]="dataTestId">
  <p-card [ngClass]="{ 'no-body': noBody }">
    @if (!noHeader) {
      <ng-template pTemplate="header">
        <div class="flex items-start">
          <ng-content select="[uiCardHeader]"></ng-content>
        </div>
      </ng-template>
    }
    <ng-content select="[uiCardBody]"></ng-content>
    @if (!noFooter) {
      <ng-template pTemplate="footer">
        <div
          class="ui-modal-actions flex justify-end"
          [ngClass]="{ 'border-top': footerTopBorder }"
        >
          <ng-content select="[uiCardFooter]"></ng-content>
        </div>
      </ng-template>
    }
  </p-card>
</div>
