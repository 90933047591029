export interface Category {
  id: string;
  officeId: string;
  title: string;
  providerEmail: string;
  isActive?: boolean;
  bufferBeforeMinutes: number;
  bufferAfterMinutes: number;
  publicNotes?: string;
  costCenterField: CostCenterFieldEnum;
  costCenterCode?: string;
  items: CategoryItem[];
  description?: string;
  notes?: string;
  isDisabled?: boolean;
}

export type CateringCategoryWithoutId = Omit<Category, "id"> & {
  id?: string;
};

export interface BufferOverlapInfo {
  hasOverlap: boolean;
  haveToReschedule: boolean;
  newMeetingTimeInMinutes?: number;
  overlapMinutes?: BufferOverlapMinutes;
}

export interface BufferOverlapMinutes {
  previous?: number;
  next?: number;
}

// Category Item Types

export interface CategoryItem {
  id: string;
  categoryId?: string;
  title?: string;
  itemType: CategoryItemTypeValue;
}

export enum CategoryItemTypeEnum {
  FREE_TEXT = "freeText",
  QUANTITY = "quantity",
  SELECTION = "selection",
}

export enum CostCenterFieldEnum {
  NONE = "none",
  OPTIONAL = "optional",
  REQUIRED = "required",
}

export type CategoryItemTypeValue =
  | CategoryItemFreeTextType
  | CategoryItemQuantityType
  | CategoryItemSingleSelectionType
  | CategoryItemMultipleSelectionType;

export type CategoryItemFreeTextType = {
  type: CategoryItemTypeEnum.FREE_TEXT;
  text?: string;
  description?: string;
};

export type CategoryItemQuantityType = {
  type: CategoryItemTypeEnum.QUANTITY;
  limit: number | null;
  quantity?: number | null;
};

export type CategoryItemSingleSelectionType = {
  type: CategoryItemTypeEnum.SELECTION;
  selectionType: SelectionTypeEnum.SINGLE;
  option: { title: string };
  options?: { title: string }[];
};

export type CategoryItemMultipleSelectionType = {
  type: CategoryItemTypeEnum.SELECTION;
  selectionType: SelectionTypeEnum.MULTIPLE;
  options: {
    title: string;
  }[];
  selectedMultipleOptions?: { title: string }[];
};

export enum SelectionTypeEnum {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

// TODO: revise this after all catering categories are migrated to new component
export interface MeetingEventCreationCateringCategoryData {
  id: string;
  items: CategoryItem[];
  title?: string;
  publicNotes?: string;
  notes?: string;
  bufferBeforeMinutes?: number;
  bufferAfterMinutes?: number;
  costCenterCode?: string;
  costCenterField?: CostCenterFieldEnum;
  isDisabled?: boolean;
}
